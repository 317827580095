<template>

  <div id="establishmentHours">
    <h1 class="days"> Hours of operation </h1>
  <div class="operation-hours"  v-for="option in options"  v-bind:key="options.indexOf(option)  ">
    <label  >{{option.label}}:</label>
    <span>Opens At: </span>
    <select class="form-control" id="" name="opens"  v-model="openHours">
      <option v-for="hour in option.opens" v-bind:key="option.opens.indexOf(hour)" v-bind:value="option.label" > {{hour}}</option>
    </select>
    <span>Closes At: </span> 
    <select name="closes" >
      <option v-for="hour in option.closes" v-bind:key="option.closes.indexOf(hour)" v-bind:value="option.label" > {{ hour }}</option>
    </select>
  </div>



  </div>
</template>



<script>


export default {
  name:'EstablishmentHours',
  data(){
    return {
      selected: 'A',
      options: this.get_establishment_hours(),
      hours :this.selectHours(),
    }
  },
  

  
  methods:{

    openHours:function(){
      console.dir(arguments)
    },
    selectHours:function(){
      var time = 1;
      var restart = 1;
      var timeSignature = "";
      var output = []

      while(time <= 12){
         
        if (time == 12 && restart < 2){
          timeSignature = "noon"
          time = 0;
          restart++;
        }
        else if (time == 12 && restart == 2){
          timeSignature = "midnight";
        }
        else if(time < 12 && restart == 1){
          timeSignature = "am";
        }
        else{
          timeSignature = "pm";
        }
        output.push((time == '0' ? '12': time)  + ":00" + " "+ timeSignature)
        time++
        
      } 
      return output;  
    },
      get_establishment_hours:function(){
   
      var hours = this.selectHours();

      let days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
      var output = [];

      days.map(function(day){
        output.push({label:day,opens:hours,closes:hours}) ;
      });
      return output;
    }
  },

}

</script>

<style lang="scss" scoped>
$color:  grey;

h1{
  color:$color;
  font-size:16px;
  
}

select {
  background-color:white;
  margin-right: 5px;
}

label {
  text-transform: capitalize;
  font-weight: bold;
  color: grey;
  width: 10%;
  display: inline-block;
}

span{
  margin-right:5px;
  font-weight: bold;
}

</style>


