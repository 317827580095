<template>

  <div v-if="entryForm" class="cell example example2 pet-info-form enter" >

    <form v-on:submit="formSubmitted" id="payment-form" :class="loading ? 'is_loading' : '' " autocomplete="">
      <div id="error_msg_holder" v-bind:class = "[ this.error_msg.length > 0 ? 'show' : 'hide'] ">
        <ul>
          <h3>{{this.error_msg_header}}</h3>
          <li v-for="item in this.error_msg" v-bind:key="item.id">
            {{ item.message | remove_underscore }}
          </li>
        </ul>
      </div>
      <div class="row">
        <h3 class="form-title">{{ entryForm.title }}</h3>
      </div>
      
      

      <div v-for="(field, idx) in entryForm.fields" :key="field.name" :class="field.missing && field.required === 'true' ? 'field has-error ' + field.classes  : 'field ' + field.classes">

          <div v-if="field.field_type === 'student' && entryForm.fields[4].value === 'Student'">
            <label for="field.name" class="form-check-label">
              {{ field.title }} <span v-if="field.required === 'true'" class="red">*</span>
            </label>
            <input 
              :id="field.name" 
              :name="field.name"
              type="text" 
              class="input text empty" 
              v-model="field.value"
              :required="field.required"
              :placeholder="field.placeholder"
            />
            <div class="baseline"></div>
          </div>


        <div v-if="field.field_type === 'date'">
            <label :for="field.name" class="form-check-label textarea">{{ field.title }} <span v-if="field.required === true" class="red">*</span></label>
            <input type="date" v-model="field.value" />
        </div>

        <div :id="field.name" v-if="field.field_type === 'select'">
          <div class="field">
          <label class="form-check-label">{{ field.title }} <span v-if="field.required === true" class="red">*</span></label>
            <v-select multiple :value="entry['categories'][field.name]" @input="(val) => selected(val, field.name,idx)" label="full_name" :options="categories[field.name]" :placeholder="'Select a ' + field.title"></v-select>
          </div>
        </div>

        <div v-if="field.field_type === 'select_single'">
          <div class="field">
          <label class="form-check-label">{{ field.title }} <span v-if="field.required === true" class="red">*</span></label>
            <v-select :value="selectedSingleCategories[field.name]" @input="(val) => selectedSingle(val, field.name)" label="full_name" :options="selectSingleCategories[field.name]" :placeholder="'Select a ' + field.title"></v-select>
          </div>
        </div>

        <div v-if="field.field_type === 'text'">
            <label :for="field.name" class="form-check-label">{{ field.title }} <span v-if="field.required === true" class="red">*</span></label>
            <input 
              :id="field.name" 
              :name="field.name"
              type="text" 
              class="input text empty" 
              v-model="field.value"
              :required="field.required"
              :placeholder="field.placeholder"
              autocomplete="asdfasdfasdfa"
            />
            <div class="baseline"></div>
        </div>


        <div v-if="field.field_type === 'image'" class="image-field">
            <label class="file-label">{{ field.title }} <span class="red">*</span> - Photo Tips</label> 
            <!-- <ExtraInfo text="For the a cover image, vertical photos work best. Submit photos in high-resolution." /> -->
            <div class="d-block">
              <input id="upload" ref="upload" type="file" accept="image/*"
                v-on:change="(e) => handleImageUploadChange(e, idx)"
                v-on:click ="(event)=> { 
                  event.target.value = null
                }"
              />
            </div>
            
            <div v-if="field.value || field.image" id="image-overlay">
              <img :src="field.value ? field.value : S3_URL + '/' + (modelType ? modelType + '_' : '') + entry.id + '/featured/' + entry.image" />

            </div>

        </div>
      

        <div v-if="field.field_type === 'image_covered'" class="image-field">
            <label class="file-label">{{ field.title }} <span class="red">*</span> - Photo Tips</label> 
            <!-- <ExtraInfo text="For the a cover image, vertical photos work best. Submit photos in high-resolution." /> -->
            <div class="d-block">
              <input id="upload" ref="upload" type="file" accept="image/*"
                v-on:change="handleImageUploadChange"
                v-on:click ="(event)=> { 
                  event.target.value = null
                }"
              />
            </div>
            
            <div v-if="field.value || field.image_id" id="image-overlay">
              <img :src="field.value ? field.value : S3_URL + '/' + field.image_id + '/conversions/large.jpg'" />
              <img id="coverImage" src='/images/cover_template.png' />
            </div>

        </div>
        <div v-if="field.name == 'hours' ">
          <establishmentHours></establishmentHours>
        </div>
        <div v-else>
        <div v-if="field.field_type === 'textarea'">
          <div class="textarea">
              <label for="item-description" class="form-check-label textarea">{{ field.title }} <span v-if="field.required === 'true'" class="red">*</span></label>
              <textarea value="field.value" required id="item-description" name="item_description" type="text" class="" 
              v-model="field.value" 
              :placeholder="field.placeholder" />
              <div class="baseline"></div>
          </div>
          <p :class="field.missing && field.required === 'true' ? 'error-message' : 'display-none'">This field cannot be blank. It must be between 1 and 600 characters.</p>
        </div>
      </div>     
        <div v-if="field.field_type === 'permission'">
          <div class="checkbox">
          
              <input required :name="field.name" id="pic-perm" type="checkbox" v-model="field.value" /> 
              <label for="pic-perm" class="checkbox-label">
                &nbsp;{{ field.title }} <span class="red">*</span>
              </label>
              
              <div v-if="field.info" v-html="field.info" />

              <div class="baseline"></div>

          </div>
          <p :class="field.missing && field.required === 'true' ? 'error-message' : 'display-none'">Permission to use photo must be checked.</p> 
        </div>


      </div>

       
        <div class="text-center full-width">
          <span class="scroll btn btn-primary" v-on:click="formSubmitted">{{ actionText }}</span>
        </div>
       
         <p class="error_msg" v-if = "this.error_msg.length > 0">Scroll up to view and correct the ({{this.error_msg.length}}) errors listed. </p> 
        
   
    </form>
  </div>
</template>



<script>
import {S3_URL} from '../../environment.js';
import vSelect from '../vue-select';
import EHours from './EstablishmentHours.vue'

export default {
  name: 'comment',
  props: ['id', 'actionText', 'modelType','classes'],
  data () {
    
    return {
      has_a_selection:false,
      error_index:0,
      error_msg_header: '',
      error_msg: [],
      open: true,
      S3_URL: S3_URL,
      selectOptions: [
        { value: 'Architectural', label: 'Architectural (including cityscapes, interiors, industrial, transportation, infrastructure)' },
        { value: 'Nature', label: 'Landscape and Nature (including seascapes, sunrises, sunsets, wildlife and plant life)' },
        { value: 'Lifestyle', label: 'Lifestyle (including sports, culture, portraits, children, pets)' },
        { value: 'Special', label: 'Special (abstract, conceptual, aerial photography, drone photography, night photography, long exposure, digitally manipulated)' },
        { value: 'Student', label: 'Photographs by Students (7th through 12th grade; students under the age of 18 must have permission of parents to enter)' },
      ],
    }
  },
  created() {

  },
  computed: {
    entryForm() {
      return this.$store.state.entryForm;
    },
    loading() {
      return this.$store.state.loading;
    },
    entry() {
      return this.$store.state.entry;
    },
    categories() {
      return this.$store.state.categories;
    },
    selectedSingleCategories() {
      // console.log('selectedSingleCategories', this.$store.state.selectedSingleCategories)
      return this.$store.state.selectedSingleCategories;
    },
    selectSingleCategories() {
      return this.$store.state.selectSingleCategories;
    }
  },
  components: {
    'v-select': vSelect,
    'EstablishmentHours' : EHours
  },
  filters:{
    remove_underscore:function(value){
      return value.replace("_"," ")
    }
  },
  methods: {
    set_error_msg_header(){
     let error_plural = this.error_msg.length > 1 ? 'errors' : 'error';
     let msg = "please fix the following \( " + this.error_msg.length + "\) " + error_plural;
     this.error_msg_header = msg;
     return msg;
    },

    set_error_msg(msg){
     msg.trim() !== "" ? this.error_msg.push({'message': msg,'id':this.error_index}) : '';
     this.error_index++
    },

    pluralize: n => n + (n === 1 ? ' reply' : ' replies'),
    selected(val, taxonomy,idx) {
     
      this.has_a_selection =true;
      // console.log('updated selected ' + this.has_a_selection)
      let form = Object.assign({}, this.entryForm);
     
       form.fields[idx].value = val;
       
      // console.log('form', form);
      this.$store.dispatch('ENTRY_FORM', {entryForm: form});
    

      let entry = Object.assign({}, this.entry);
      console.dir(val[0]);
      console.dir(taxonomy);
      entry.categories[taxonomy] = val;
      this.$store.dispatch('SET_STATE', {key: 'entry', data: entry})
    },
    selectedSingle(val, taxonomy) {
      
      this.has_a_selection =true;
      console.dir(val)
      console.dir(taxonomy)
      let selectedSingleCategories = Object.assign({}, this.selectedSingleCategories);
      selectedSingleCategories[taxonomy] = val[0];
      this.$store.dispatch('SET_STATE', {key: 'selectedSingleCategories', data: selectedSingleCategories})
    },
    handleImageUploadChange(e, idx){

      // console.log('image index');
      
      // add the uploaded image to the input
      const reader = new FileReader();
      const file = e.target.files[0];
      const file_size = file.size;

      // console.log(file_size);
      if (file_size > 10000000) {
        alert('Your file is too big (' + file_size/1000000 + 'mb). Please upload an image less than 10mb.'); // push into errors
        return;
      }

      reader.onload = (upload) => {
        var img = new Image();
        img.src = reader.result;
        img.onload = function(e) {

          let form = Object.assign({}, this.entryForm);
          form.fields[idx].value = upload.target.result;
          form.fields[idx].filename = file.name;
          form.fields[idx].filetype = file.type;
          this.$store.dispatch('ENTRY_FORM', {entryForm: form});

        }.bind(this);

      };

      
      reader.readAsDataURL(file);

    },
    
    /** 
     * @param field - a form field object
     * @return - returns the value of the required property
     * */ 
    fieldIsRequired(field){
      return field.required ? field.required : false ;
    },

    valueIsEmpty(value){
      var valid = true;
      if(value !== undefined ){
        if(typeof value == "string"){
          if (value.trim() !== ''){
            valid = false;
          }
        }
        if(value !== false){
          valid = false;
        }
      }
      return valid;
     
    },
   
   /** 
     * @param field - a form field object
     * checks if the field is a required field if yes then check if its empty or not
     * @return - if the value is not empty return true else false,defaults to true (for non-required fields)
     * */ 
    requiredFieldHasValue(field){
  

      if(field.name === 'hcategory' || field.name === 'category' ){
        var has_selected = this.selectedSingleCategories.category !== null;
         
        if (has_selected){
         
          return true;
        }
        else
        {
        
          return false;
        }
      }
      let has_value = this.fieldIsRequired(field) ? !this.valueIsEmpty(field.value) : true;
      return has_value;
    },

    retrieveHours:function(){
      var opHours = document.querySelectorAll('.operation-hours');
      let day = '';
      let open = '';
      let close = '';
      let output ='';
      
      opHours.forEach(opHour => {
     
        // let openIndex = opHour.children[2].selectedIndex;
        // open = opHour.children[2].options[openIndex].innerText;
        // let closeIndex = opHour.children[4].selectedIndex;
        // close = opHour.children[4].options[closeIndex].innerText;
        // day =  opHour.children[0].innerText;
        // output += day + ": " + open + " - " + close + " <br/> " ;
      });

      return output;
    },
    
    validateItem() {
      let showMissing = false;
      let form = Object.assign({}, this.entryForm);
      let requiredFieldHasValue = false;
      let fieldname ='';
      
      form.fields.map((field, index) => {
        if(field.name === 'hours'){
          field.value = this.retrieveHours();
        }

        if( !this.requiredFieldHasValue(field) ) {       
  
          fieldname = field.name.replace("hcategory","categories");
          this.set_error_msg(fieldname + ' cannot be blank');
          showMissing = true;
          field.missing = true;
        }
      });
      
      this.set_error_msg_header();
      // console.log("showing form",form)
      if (showMissing) {
        this.$store.dispatch('ENTRY_FORM', {entryForm: form});
        this.missingRequired = true;
        return false;
      }
      
      return true;
      
    },
    
    formSubmitted(e) {
      // clear error messages
      this.error_index=0;
      this.error_msg_header= '';
      this.error_msg = [];
      e.preventDefault();
      const isValid = this.validateItem();
      if (!isValid) {
        return;
      }
      
      this.$emit('submitted');
    
    },
  },
}
</script>

<style lang="scss">
#error_msg_holder.hide {
    height: 0;
    overflow: hidden;
}

#error_msg_holder.show {
    height: auto;
    overflow: hidden;
}

#error_msg_holder ul{
  margin: 0;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #d92626;
}

#error_msg_holder h3 {
  text-decoration: underline;
  color: #ffffff;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 18px;
  margin: 0;
  line-height: 26px;
}

#error_msg_holder ul li{
  list-style-type: none;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  letter-spacing: 0.3px;
}

#error_msg_holder ul li::before {
  content: "- ";
}

p.error_msg{
  color: #d92626;
  margin: 20px auto;
}

/* payment form */
// remove firefox's red border around required input items
input:invalid {
	box-shadow: none;
}

.example.example2 {
	background-color: #fff;
  }
  
  .example.example2 * {
	// font-family: Source Code Pro, Consolas, Menlo, monospace;
	font-size: 16px;
	font-weight: 500;
  }
  
  .example.example2 .row {
	display: -ms-flexbox;
	display: flex;
	margin: 0 5px 10px;
  }
  
  .example.example2 .field {
	position: relative;
	width: 100%;
  margin-bottom: 20px;
	// height: 50px;
	// margin: 0 10px;
  }
  
  .example.example2 .field.half-width {
    width: 50%;
    display: inline-block;
  }

	.field.half-width .baseline, 
	.field.quarter-width .baseline {
		width: 95% !important;
  }
  
  .example.example2 .field.quarter-width {
    width: calc(25%);
    display: inline-block;
  }
  
  .example.example2 .baseline {
	width: 100%;
	height: 1px;
	background-color: #cfd7df;
	transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .example.example2 label {
	width: 100%;
	color: #787878;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-bottom: 0px;
  }

  .example.example2 .checkbox-label:hover,
  .example.example2 input[type="checkbox"] {
	  cursor: pointer;
  }
  
  .example.example2 .input.text {
	width: 100%;
	color: #32325d;
	background-color: transparent;
  }
  
  
//   .example.example2 .input.text.StripeElement {
//     opacity: 0;
//     transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
//     will-change: opacity;
//   }

  
  .example.example2 .input.text.focused + label,
  .example.example2 .input.text:not(.empty) + label {
	color: #aab7c4;
	transform: scale(0.85) translateY(-25px);
	cursor: default;
  }
  
  .example.example2 .input.text.focused + label {
	color: #24b47e;
  }
  
  .example.example2 .input.text.invalid + label {
	color: #ffa27b;
  }
  
  .example.example2 .input.text.focused + label + .baseline {
	background-color: #24b47e;
  }
  
  .example.example2 .input.text.focused.invalid + label + .baseline {
	background-color: #e25950;
  }
  
  .example.example2 input.text, .example.example2 button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border-style: none;
  }
  
  .example.example2 input.text:-webkit-autofill {
	-webkit-text-fill-color: #e39f48;
	transition: background-color 100000000s;
	-webkit-animation: 1ms void-animation-out;
  }
  
  .example.example2 .StripeElement--webkit-autofill {
	background: transparent !important;
  }
  
  .example.example2 input.text, .example.example2 button {
	-webkit-animation: 1ms void-animation-out;
  }
  
  .example.example2 button {
	display: block;
	width: 100%;
	height: 40px;
	background-color: #24b47e;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
  }
  
  .example.example2 .error svg {
	margin-top: 0 !important;
  }
  
  .example.example2 .error svg .base {
	fill: #e25950;
  }
  
  .example.example2 .error svg .glyph {
	fill: #fff;
  }
  
  .example.example2 .error .message {
	color: #e25950;
  }
  
  .example.example2 .success .icon .border {
	stroke: #abe9d2;
  }
  
  .example.example2 .success .icon .checkmark {
	stroke: #24b47e;
  }
  
  .example.example2 .success .title {
	color: #32325d;
	font-size: 16px !important;
  }
  
  .example.example2 .success .message {
	color: #8898aa;
	font-size: 13px !important;
  }
  
  .example.example2 .success .reset path {
	fill: #24b47e;
  }

.example {
	padding: 40px;
}

.example {
	-ms-flex-align: center;
	align-items: center;
	border-radius: 4px;
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
	margin-left: -10px;
	margin-right: -10px;
}


.cell {
	padding: 25px 10px;
	margin-top: 18px;
}
@media (min-width: 560px) {
	.cell {
		padding: 130px 100px;
		padding: 40px 40px;
		// max-width: 730px;
	}
}
.example.example2 {
	background-color: #fff;
}
.example.example2 label {
	font-size: 17px;
}

 .example.example2 .field.has-error {
	background-color: rgba(255, 0, 0, 0.06);
}
.Select.has-error {
	border: 1px solid red;
}
#item-description {
	box-shadow: none !important;
}




.example.example2 input[type="checkbox"] {
	margin-left: 0px !important;
	margin-top: 7px;
}



.enter-text {
	font-size: 16px;
	padding-left: 10px;
	padding-top: 10px;
}

// Put text to left of form
@media (min-width: 1043px) {
	.enter-text {
		width: 40%;
		float: left;
		margin-right: 3%;
	}
	
	.pet-info-form.enter {
		width: 58%;
		float: left;
	}
}



.file-label,
.checkbox-label {
	position: relative !important;
	text-overflow: initial !important;
	white-space: initial !important;
}
.file-label {
	width: auto !important;
}

.image-field.field {
	height: initial !important;
	margin-top: 30px !important;
	padding-bottom: 10px;
	margin-bottom: 10px !important;
}

#image-overlay img {
	max-width: 100%;
}

.checkbox.field {
	height: initial !important;
}

/* Loading Crescent for payment form */
#payment-form.is_loading {
	opacity: .5;
	pointer-events: none;
}
.example.example2 .lds-css.ng-scope {
	position: relative;
	text-align: center !important;
	top: 55px;
	left: -15px;
}
.example.example2 .lds-eclipse {
	position: absolute;
	width: 100% !important;
	text-align: center !important;
}
.example.example2 .lds-eclipse div {
	position: relative !important;
	display: inline-block;
}
.form-title {
	font-size: 30px !important;
	font-weight: bold !important;
	margin-top: 0px;
}

.full-width {
	width: 100%;
}

.field.textarea {
	height: auto !important;
	margin-top: 20px !important;
}
.form-check-label {
	width: auto !important;
}
.form-check-label.textarea {
	position: relative !important;
	margin-bottom: -8px;
}

#item-description {
	width: 100%;
	border: 1px solid #cfd7df;
	border-bottom: none;
	padding: 3px;
	padding-left: 5px;
}
.v-select .selected-tag .close {
    width: initial;
    height: initial;
}

.selected-tag {
    width: 100%;
}
.btn.btn-primary {
    background: #767676;
    padding: 10px 20px;
    color: white;
}
.btn.btn-primary:hover {
    cursor: pointer;
}

</style>
