import { render, staticRenderFns } from "./SuperCategoriesCategoryEdit.vue?vue&type=template&id=e612a16a&"
import script from "./SuperCategoriesCategoryEdit.vue?vue&type=script&lang=js&"
export * from "./SuperCategoriesCategoryEdit.vue?vue&type=script&lang=js&"
import style0 from "./SuperCategoriesCategoryEdit.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "SuperCategoriesCategoryEdit.vue"
export default component.exports